import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Swal from "sweetalert2";
import {Format } from '../../systemadministration/usermanagement/CheckRole';
import {
    getAssignedTextbooks,
    addAssignedTextBook,
    removeAssignedTextBook,
    getAvailableTextbooks
} from "../../../services/ProgramService";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import axios from "axios";
const typeFilterData = [{value: "ebook", label: "E-book"},{value: "Book", label: "Book"}]
const addedType = [{value: 0, label: 'Required'},{value: 1, label: 'Optional'}]
const PrescribedTextbooks = (props) => {
    const history = useHistory();
    const { id, tab } = useParams();
    const [typeFilterCheck, setTypeFilterCheck] = useState({});
    
    const [search, setSearch] = useState("");
    const [searchAvailable, setSearchAvailable] = useState("");
    const [assignedBooks, setAssignedBooks] = useState({});
    const [availableBooks, setAvailableBooks] = useState([]);
    const [loadingAvailable, setLoadingAvailable] = useState(false);
    const [loading,setLoading] = useState(false)
    const [updateAvailable, setUpdateAvailable] = useState(false);
    const [typeFilter, setTypeFilter] = useState({ arr: [], checkObj: {} });
    // Pagination states
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10); // You can adjust this limit as needed
    const [totalPages, setTotalPages] = useState(1);
    const [total, setTotal] = useState(0);
    
    useEffect(() => {
        setLoading(true);
        const cancelTokenSources = [];
        
        const getAssignedBooks = async () => {      
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });
            
            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);
            
            try {
                const res = await getAssignedTextbooks(id, source.token);
                if (res.status === 200) {
                    setAssignedBooks(res.data.data);
                }
                setLoading(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
                setLoading(false);
            }
        };
        console.log("tab", tab, "id", id);
        getAssignedBooks();
        
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [updateAvailable]);
    
    useEffect(() => {
        setLoading(true);
        const cancelTokenSources = [];
        
        const getAssignedBooks = async () => {      
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });
            
            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);
            
            try {
                const res = await getAssignedTextbooks(id, source.token);
                if (res.status === 200) {
                    setAssignedBooks(res.data.data);
                }
                setLoading(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
                setLoading(false);
            }
        };
        console.log("tab", tab, "id", id);
        getAssignedBooks();
        
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, []);
    
    useEffect(() => {
        const cancelTokenSources = [];
        setLoadingAvailable(true);
        const listOfAvailableTextBooks = async () => {
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });
            
            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);
            try {
                // Pass `page` and `limit` to fetch paginated data
                const res = await getAvailableTextbooks(id, page, limit, searchAvailable, typeFilter.arr, source.token);
                if (res.status === 200) {
                    setAvailableBooks(res.data.data);
                    setTotalPages(res.data.pagination.totalPages); // Update total pages based on response
                    setTotal(res.data.pagination.total);
                }
                setLoadingAvailable(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
                setLoadingAvailable(false);
            }
        };
        
        listOfAvailableTextBooks();
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
        
    }, [updateAvailable, page, limit, searchAvailable, typeFilter]);
    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };
    
    /**
    * Handles change in rows per page of the Available Textbooks table.
    * @param {number} newPerPage The new number of rows per page.
    * @param {number} page The current page number.
    */
    const handlePerRowsChange = (newPerPage, page) => {
        setLimit(newPerPage);
    };
    
    const handleLoadAvailable = () => {
        const cancelTokenSources = [];
        setLoadingAvailable(true);
        const listOfAvailableTextBooks = async () => {
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });
            
            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);
            try {
                // Pass `page` and `limit` to fetch paginated data
                const res = await getAvailableTextbooks(id, page, limit, searchAvailable, typeFilter.arr, source.token);
                if (res.status === 200) {
                    setAvailableBooks(res.data.data);
                    setTotalPages(res.data.pagination.totalPages); // Update total pages based on response
                    setTotal(res.data.pagination.total);
                }
                setLoadingAvailable(false);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
                setLoadingAvailable(false);
            }
        };
        
        listOfAvailableTextBooks();
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }
    
    const resetFilterAvailable = () => {
        setSearch("");
        setTypeFilter({ arr: [], checkObj: {} });
        setUpdateAvailable(prev => !prev);
        
    };
    const handleStatusFilter = (e) => {
        const value = e.target.value;
        let arr = typeFilter.arr;
        if (arr.includes(value)) {
            arr.splice(arr.indexOf(value), 1);
        } else {
            arr.push(value);
        }
        setTypeFilter({ arr, checkObj: {} });
    };
    const dataToRender = () => {
        let updatedData = assignedBooks;
        
        if (search.length) {
            updatedData = updatedData.filter((item) => 
                item.title.toString().toLowerCase().includes(search.toLowerCase())
        );
    }
    
    return updatedData;
};

const typeSort = (a, b) => {
    const typeA = a?.type.toString().toLowerCase();
    const typeB = b?.type.toString().toLowerCase();
    
    if (typeA > typeB) return 1;
    if (typeA < typeB) return -1;
    return 0; // Equal types
};

const handleSearchAvailable = (e) => {
    const value = e.target.value;
    setSearchAvailable(value);
};
const handleSearchAssigned = (e) => {
    const value = e.target.value;
    setSearch(value);
};
const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Title", "Author", "Publisher", "ISBN", "Type", "Required"];
    
    // Prepare the data for export
    data = data.map((row) => ({
        Title: row.title || "-", // Fallback if title is missing        
        Author: row.author || "-",
        Publisher: row.publisher || "-",
        ISBN: row.isbn || "-",
        Type: row.type ? row.type : "-", // Assuming `row.type` is an object
        Required: row.addedType == 0 ? "Required" : "Recommended",
    }));
    
    if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
        const blobURL = window.URL.createObjectURL(blob);
        
        // Create an anchor tag for downloading the file
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        document.body.appendChild(anchor); // Append to body for Firefox compatibility
        anchor.click();
        document.body.removeChild(anchor); // Remove the anchor after download
        
        // Clean up the URL object
        setTimeout(() => {
            URL.revokeObjectURL(blobURL);
        }, 1000);
    } else if (fileType === "excel") {
        const compatibleData = data.map((row) => {
            const obj = {};
            header.forEach((col) => {
                obj[col] = row[col];
            });
            return obj;
        });
        
        const wb = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        
        return false; // Downloading is handled
    } else if (fileType === "pdf") {
        const compatibleData = data.map((row) => [row.Title, row.Author, row.Publisher, row.ISBN, row.Type, row.Required == 0 ? "Required" : "Recommended"]); // Ensure the right properties
        const doc = new JsPDF();
        doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
                minCellHeight: 10,
                minCellWidth: 5,
                halign: "left",
                fontSize: 8,
            },
        });
        doc.save(`${fileName}.pdf`);
        
        return false; // Downloading is handled
    }
};


const addAvailableBook = (rowid, type) => {    
    const data = {
        id: id, // Use the `row.id` directly
        textbook: rowid, // Assuming this is defined in the outer scope
        type: type
    };
    
    addAssignedTextBook(data)
    .then((res) => {
        Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message || "Updated Successfully", // Fixed typo: "massage" to "message"
        });
        setUpdateAvailable(!updateAvailable);
    })
    .catch((err) => {
        console.error("Error updating assigned textbooks:", err);
    });
};


const columns = useMemo(() => [
    {
        name: "title",
        selector: "title",
        sortable: true,
        wrap: true,
        cell: (row) =>
            row.title ? (
            <span
            className="as-text-blue curser feature-name"
            title={row.title}
            onClick={() => {history.push(`/support/student-hub-library/resource-management/edit/${id}/dashboard/physical`)}}            
            >
            <span title={row.title} className="textLimit100">{row.title}</span>
            </span>
        ) : (
            "-"
        ),
    },
    {
        name: "Author",
        selector: "author",
        sortable: true,
        wrap: true,
        cell: (row) =>
            row.author ? (
            <span>
            <span author={row.author} className="textLimit100">{row.author}</span>
            </span>
        ) : (
            "-"
        ),
    },
    {
        name: "Publisher",
        selector: "publisher",
        sortable: true,
        wrap: true,
        cell: (row) =>
            row.publisher ? (
            <span>
            <span publisher={row.publisher} className="textLimit100">{row.publisher}</span>
            </span>
        ) : (
            "-"
        ),
    },
    {
        name: "ISBN",
        selector: "isbn",
        sortable: true,
        wrap: true,
        cell: (row) =>
            row.isbn ? (
            <span>
            <span isbn={row.isbn} className="textLimit100">{row.isbn}</span>
            </span>
        ) : (
            "-"
        ),
    },
    {
        name: "Type",
        selector: "type",
        sortable: true,
        sortFunction: typeSort,
        wrap: true,
        cell: (row) =>
            row.type ? ( <Format status={row.type.toString()} />
    ) : (
        "-"
    )
},
{
    name: "Required",
    selector: "addedType",
    sortable: true,
    wrap: true,
    cell: (row) =>
        row.addedType !== null ? (<>
        {row.addedType == 0 ? <span className="cat-blue">Required</span> : <span className="cat-purple">Recommended</span>}
        </>
    ) : (
        "-"
    ),
},
{
    name: "Actions",
    cell: (row) => (
        <div className="assessment-08">
        <div className="as-buttons">
        <a
        title="Open"
        className="btn btn-primary rounded-circle"
        href={`/support/student-hub-library/resource-management/edit/${row.id}/dashboard/physical`}
        >
        <i className="fal fa-folder-open"></i>
        </a>
        <button
        title="Unlink"
        className="btn btn-danger rounded-circle"
        onClick={() => handleUnlinkBook(row.id)}
        >
        <i className="fal fa-unlink"></i>
        </button>
        </div>
        </div>
    ),
},
]);
const Availablecolumns = useMemo(() => [
    {
        name: "Title",
        selector: "title",
        sortable: false,
        wrap: true,
        cell: (row) =>
            (
            <span
            title={row.name}
            className="as-text-blue curser feature-name"
            onClick={() => {
                Swal.fire({
                    title: "Please select addition type",
                    icon: "question",
                    showCancelButton: true,
                    showDenyButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#8064ff",
                    confirmButtonText: "Required",
                    cancelButtonText: "Recommended",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        addAvailableBook(row.id, 0); // Required action
                    } else if (result.isDismissed) {
                        addAvailableBook(row.id, 1); // Recommended action
                    } 
                    // result.isDismissed is true when Cancel is clicked; no action needed
                });         
            }}
            >
            <span className="textLimit75">{row.title}</span>
            </span>
        ),
    },
    {
        name: "Author",
        selector: "author",
        sortable: true,
        wrap: true,
        cell: (row) =>
            row.author ? (
            <span>
            <span author={row.author} className="textLimit100">{row.author}</span>
            </span>
        ) : (
            "-"
        ),
    },
    {
        name: "Publisher",
        selector: "publisher",
        sortable: true,
        wrap: true,
        cell: (row) =>
            row.publisher ? (
            <span>
            <span publisher={row.publisher} className="textLimit100">{row.publisher}</span>
            </span>
        ) : (
            "-"
        ),
    },
    {
        name: "ISBN",
        selector: "isbn",
        sortable: true,
        wrap: true,
        cell: (row) =>
            row.isbn ? (
            <span>
            <span isbn={row.isbn} className="textLimit100">{row.isbn}</span>
            </span>
        ) : (
            "-"
        ),
    },
    {
        name: "Type",
        selector: "type",
        sortable: false,
        sortFunction: typeSort,
        wrap: true,
        cell: (row) =>
            row.type ? ( <Format status={row.type.toString()} />
    ) : (
        "-"
    ),
},
{
    name: "Actions",
    cell: (row) => (
        <div className="assessment-08">
        <div className="as-buttons">
        <button
        className="btn btn-primary rounded-circle"
        title="Add"
        type="button"
        onClick={() => {
            Swal.fire({
                title: "Please select addition type",
                icon: "question",
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#8064ff",
                confirmButtonText: "Required",
                cancelButtonText: "Recommended",
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    addAvailableBook(row.id, 0); // Required action
                } else if (result.isDismissed) {
                    addAvailableBook(row.id, 1); // Recommended action
                } 
                // result.isDismissed is true when Cancel is clicked; no action needed
            });         
        }}
        >
        <i className="fal fa-plus"></i>
        </button>
        <a
        className="btn btn-primary rounded-circle"
        href={`/support/student-hub-library/resource-management/edit/${row.id}/dashboard/physical`}
        >
        <i className="fal fa-folder-open"></i>
        </a>
        </div>
        </div>
    ),
},
]);

const handleUnlinkBook = (bookid) => {
    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!",
    }).then((result) => {
        if (result.isConfirmed) {
            removeAssignedTextBook({ id: id, textbook: bookid })                
            .then((res) => {
                Swal.fire("Unlinked!", "Textbook has been unlinked.", "success");
                setUpdateAvailable(!updateAvailable);
            })
            .catch((error) => {
                console.error("Error unlinking textbook:", error);
            });
        }
    });
};    

return (
    <div className="custom-table-div filter-search-icon card card-table-custom">
    <div className="filter-search-head">
    <div className="filter-search-head-left">
    <div className="system-administration-table table-responsive">
    <div className="table-responsive-div">
    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
    <div id="assessment-table-main_filter" className="dataTables_filter">
    <label>
    <input
    type="search"
    placeholder="Search"
    aria-controls="assessment-table-main"
    onChange={handleSearchAssigned}
    value={search}
    />
    </label>
    <div className="filter-button-group">
    <div className="filter-eff filter-data-btn">
    <button className="filter-buttons">
    <i className="fal fa-filter"></i>
    </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div className="filter-search-bar-blk">
    <div className="button-reset dropdown-comman">
    <button
    className="btn btn-primary"
    onClick={() => resetFilterAvailable()}
    title="Reset"
    >
    <i className="fal fa-redo"></i>Reset
    </button>
    </div>
    <div className="files-export-group">
    {["excel", "csv", "pdf"].map((type) => (
        <button
        key={type}
        type="button"
        className="btn btn-files"
        onClick={() => exportData(type, "Textbooks")}
        title={`Export ${type.toUpperCase()}`}
        >
        <i className={`fal fa-file-${type} icon`}></i>
        </button>
    ))}
    </div>
    </div>
    </div>
    </div>
    
    <div className="filter-search-head-right">
    <button
    className="btn btn-primary mr-2"
    title="Add Textbook"
    data-toggle="modal"
    data-target="#Select-Features"
    onClick={() => handleLoadAvailable()}
    >
    <i className="fal fa-plus"></i>Add Textbook
    </button>
    </div>
    </div>
    
    <DataTable
    data={dataToRender()}
    columns={columns}
    pagination={true}
    progressComponent={<SkeletonTicketList />}
    progressPending={loading}
    noDataComponent={Str.noRecord}
    defaultSortAsc={true}
    defaultSortField={"addedType"}
    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
    />
    
    <div className="topic-add-modal modal fade" id="Select-Features" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">
    <div className="modal-content">
    <div className="modal-header modal-header-custom">
    <h5 className="modal-title"><i className="fal fa-plus"></i> Add Textbook</h5>
    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">×</span>
    </button>
    </div>
    <div className="modal-body">
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
    <div className="custom-table-div filter-search-icon card-table-custom">
    <div className="search-filter-div">
    <div className="search-filter-div-left">
    <div className="system-administration-table table-responsive">
    <div className="table-responsive-div">
    <div id="available-books-table_wrapper" className="dataTables_wrapper no-footer">
    <div id="available-books-table_filter" className="dataTables_filter">
    <label>
    <input
    type="search"
    placeholder="Search"
    aria-controls="available-books-table"
    onChange={handleSearchAvailable}
    value={searchAvailable}
    />
    </label>
    <div className="filter-eff filter-data-btn">
    <button className="filter-buttons">
    <i className="fal fa-filter"></i>
    </button>
    </div>
    </div>            
    </div>        
    </div>
    <div className="filter-button-group">
    <div className="filter-scroll">
    <div className="assessment-table-filter">
    <div className="btn-type-filter dropdown-comman">
    <div className={`dropdown multiselect `}>
    <button
    className={`btn btn-default dropdown-toggle ${
        typeFilter?.arr?.length ? 'btn-selected' : ''
    }`}
    type="button"
    id="dropdownMenu1"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="true"
    title="Format"
    >
    <span>
    Format <i className="fal fa-angle-down"></i>
    </span>
    </button>
    <form className="dropdown-menu remove-list-style">
    <ul aria-labelledby="dropdownMenu1">
    {typeFilterData.map((resource, idx) => (
        <li key={idx} className="item-text-green">
        <input
        type="checkbox"
        id={resource.value}
        value={resource.value}
        onClick={handleStatusFilter}
        checked={typeFilterCheck[resource.value]}
        />
        <label htmlFor={resource.value}>
        <Format status={resource.value.toString()}/>
        </label>
        </li>
    ))}
    </ul>
    </form>
    </div>
    </div>
    </div>
    </div>
    <div className="button-reset dropdown-comman">
    <button
    className="btn btn-primary"
    onClick={() => resetFilterAvailable()}
    title="Reset"
    >
    <i className="fal fa-redo"></i>Reset
    </button>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    <DataTable
    data={availableBooks}
    columns={Availablecolumns}
    pagination={true}
    progressComponent={<SkeletonTicketList />}
    progressPending={loadingAvailable}
    noDataComponent={Str.noRecord}
    defaultSortAsc={true}
    defaultSortField={"name"}
    paginationServer
    onChangeRowsPerPage={handlePerRowsChange}
    onChangePage={handlePageChange}
    paginationTotalRows={total}
    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
    />
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
);
}


export default PrescribedTextbooks;