import React, { useEffect, useState, useMemo, useImperativeHandle, forwardRef } from 'react';
import DataTable from 'react-data-table-component';
import SkeletonTicketList from '../../loaders/SkeletonTicketList';
import { TABLE_ROWS_PER_PAGE, AIE_BRIEF_FOLDER_ID, GDC_BRIEF_FOLDER_ID } from '../../utils/Constants';
import { GlobalIconNames } from '../../utils/GlobalIconNames';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Str from './Str';
import { handleTableScroll } from '../../utils/commonFunction';
import { getSharepointFolderContents, uploadToSharepoint, uploadLargeFilesToSharepoint, SharepointCreateFolder } from '../../services/SharepointIntegrationService';
import Swal from 'sweetalert2';

function SharepointFileUpload({
    totalRows = 0,
    bottomButton = [],
    otherTableProps = {},
    onAddFile
}, ref) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentFolderPath, setCurrentFolderPath] = useState([]);
    const [rootClick, setRootClick] = useState(false);
    const [state, setState] = useState({
        page: 1,
        perPage: 10
    });
    const [search, setSearch] = useState('');
    const [originalData, setOriginalData] = useState([]);
    const [showNewFolderInput, setShowNewFolderInput] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [isGDC, setIsGDC] = useState(false);

    const handleBrowseAMI = (file) => {
        onAddFile(file);
    };

    const handleCreateFolder = async () => {
        try {
            setLoading(true);
            setShowNewFolderInput(false);
            const folderId = currentFolderPath.length > 0 ? currentFolderPath[currentFolderPath.length - 1].id : null;
            if (!folderId) {
                console.error('No parent folder selected');
                Swal.fire({
                    icon: 'error',
                    title: 'Folder Creation Failed',
                    text: 'There was an error creating the folder in SharePoint.',
                });
                return;
            }
            const formData = new FormData();
            formData.append('folderID', folderId);
            formData.append('folderName', newFolderName);
            let response;
            if (isGDC) {
                response = await SharepointCreateFolder(formData, '?location=GDC');
            } else {
                response = await SharepointCreateFolder(formData);
            }

            // Display success notification using Swal
            Swal.fire({
                icon: 'success',
                title: 'Folder Created',
                text: 'The folder has been successfully created in SharePoint.',
            }).then(() => {
                // Refresh the screen with updated data
                refreshData();
            });
        } catch (error) {
            console.error('Error creating folder:', error);
            Swal.fire({
                icon: 'error',
                title: 'Folder Creation Failed',
                text: 'There was an error creating the folder in SharePoint.',
            });
        } finally {
            setLoading(false);
        }
    };

    // Function to handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        handleUpload(file);
    };

    const handleUpload = async (selectedFile) => {
        if (!selectedFile) {
            console.error('No file selected');
            return;
        }
        try {
            setLoading(true);
            const basePath = isGDC ? 'General/GDC Staff Drive/06_Academic/' : '06_Academic/';
            const folderPath = `${basePath}${currentFolderPath.map(folder => folder.name).join('/')}`;
            const fileName = selectedFile.name;
            const formData = new FormData();
            formData.append('folderPath', folderPath);
            formData.append('fileName', fileName);
            formData.append('file', selectedFile);
            const maxSize = 4 * 1024 * 1024; // 4 MB in bytes
            if (selectedFile.size <= maxSize) {
                let response;
                if (isGDC) {
                    response = await uploadToSharepoint(formData, '?location=GDC');
                } else {
                    response = await uploadToSharepoint(formData);
                }
            } else {
                let response;
                if (isGDC) {
                    response = await uploadLargeFilesToSharepoint(formData, '?location=GDC');
                } else {
                    response = await uploadLargeFilesToSharepoint(formData);
                }
            }
            // Display success notification using Swal
            Swal.fire({
                icon: 'success',
                title: 'Upload Successful',
                text: 'The file has been successfully uploaded to SharePoint.',
            }).then(() => {
                // Refresh the screen with updated data
                refreshData();
            });
        } catch (error) {
            console.error('Error uploading file:', error);
            Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                text: 'There was an error uploading the file to SharePoint.',
            });
        } finally {
            setLoading(false);
        }
    };

    const refreshData = async () => {
        try {
            let response;
            if (isGDC) {
                response = await getSharepointFolderContents(currentFolderPath[currentFolderPath.length - 1].id, '&location=GDC');
            } else {
                response = await getSharepointFolderContents(currentFolderPath[currentFolderPath.length - 1].id);
            }
            setData(response.data);
        } catch (error) {
            console.error('Error fetching folder data:', error);
        } finally {
        }
    };

    const handlePageChange = (pageNo) => {
        setState((prevState) => ({ ...prevState, page: pageNo }));
    };

    const toggleNewFolderInput = () => {
        setShowNewFolderInput(!showNewFolderInput);
        setNewFolderName(''); // Clear the new folder name input
    };

    function bytesToMB(bytes) {
        if (bytes === 0) return '0 MB';

        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

        return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + ' ' + sizes[i];
    }

    const filterDataBySearchTerm = (searchTerm) => {
        const searchTermLowerCase = searchTerm.toLowerCase();
        return originalData.filter(item =>
            item.name.toLowerCase().includes(searchTermLowerCase)
        );
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value;
        setSearch(searchTerm); // Update the search state
        const filteredData = filterDataBySearchTerm(searchTerm);
        setData(filteredData); // Filter the data based on the search term
    };
    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                setCurrentFolderPath([]);
                let response;
                if (isGDC) {
                    response = await getSharepointFolderContents(GDC_BRIEF_FOLDER_ID, '&location=GDC');
                } else {
                    response = await getSharepointFolderContents(AIE_BRIEF_FOLDER_ID);
                }
                // Store the fetched data in the state
                setOriginalData(response.data);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
        setRootClick(false)
    }, [rootClick]);

    const handleFolderClick = async (folderId) => {
        try {
            setLoading(true);
            const parentFolder = data.find(folder => folder.id === folderId);
            let response;
            if (isGDC) {
                response = await getSharepointFolderContents(folderId, '&location=GDC');
            } else {
                response = await getSharepointFolderContents(folderId);
            }
            setData(response.data);
            setOriginalData(response.data);
            setCurrentFolderPath(prevPath => [...prevPath, parentFolder]); // Store visited folder item
        } catch (error) {
            console.error('Error fetching folder data:', error);
        } finally {
            setLoading(false);
        }
    };

    useImperativeHandle(ref, () => ({
        clearStates: () => {
            setIsGDC(false);
            setRootClick(true);
            setSearch('');
            setCurrentFolderPath([]);
            setShowNewFolderInput(false);
        }
    }));

    const handleSidebarClick = async (folderId) => {
        try {
            setLoading(true);
            let response;
            if (isGDC) {
                response = await getSharepointFolderContents(folderId, '&location=GDC');
            } else {
                response = await getSharepointFolderContents(folderId);
            }
            setData(response.data);
            setOriginalData(response.data);
            // Find the index of the folder with matching folderId
            const index = currentFolderPath.findIndex(folder => folder.id === folderId);
            if (index !== -1) {
                // Remove all elements after the matching folder
                setCurrentFolderPath(prevPath => prevPath.slice(0, index + 1));
            } else {
                console.warn('Folder not found');
            }
        } catch (error) {
            console.error('Error fetching folder data:', error);
        } finally {
            setLoading(false);
        }
    };

    const getFileIconClass = (fileName) => {
        const fileExtension = fileName.type;
        switch (fileExtension.toLowerCase()) {
            case 'folder':
                return 'fas fa-folder fa-lg';
            case 'pdf':
                return 'fal fa-file-pdf fa-lg';
            case 'zip':
                return 'fal fa-file-archive fa-lg';
            case 'doc':
            case 'docx':
                return 'fal fa-file-word fa-lg';
            case 'ppt':
            case 'pptx':
                return 'fal fa-file-powerpoint fa-lg';
            case 'xls':
            case 'xlsx':
                return 'fal fa-file-excel fa-lg';
            case 'image':
                return 'fal fa-file-image fa-lg';
            case 'audio':
                return 'fal fa-file-audio fa-lg'
            case 'video':
                return 'fal fa-file-video fa-lg'
            default:
                return 'fal fa-file fa-lg';
        }
    };

    const getFileIconColor = (fileName) => {
        const fileExtension = fileName.type;
        switch (fileExtension.toLowerCase()) {
            case 'pdf':
            case 'audio':
                return 'red';
            case 'ppt':
            case 'pptx':
            case 'video':
                return 'orange';
            case 'image':
            case 'xlsx':
            case 'xls':
                return 'green';
            case 'zip':
                return 'grey';
            default:
                return 'steelblue';
        }
    };

    const columns = useMemo(() => [
        {
            name: 'Name',
            selector: 'name',
            wrap: true,
            maxWidth:'200px',
            cell: (row) => {
                const iconClass = getFileIconClass(row); // Get file icon class
                const iconColor = getFileIconColor(row); // Get file icon color
                return (
                    <div style={{  width: "100%", overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <Link
                            title={row.name}
                            className="feature-name"
                            to="#"
                            onClick={() => row.download_link ? window.open(row.webUrl, '_blank') : handleFolderClick(row.id)}
                        >
                            <span>
                                <i className={iconClass} style={{ color: iconColor }}></i>
                                <span className='curser as-text-blue'>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {row.name}
                                </span>
                            </span>
                        </Link>
                    </div>
                );
            },
        },


        {
            name: 'Size',
            selector: 'size',
            cell: (row) => row.size ? bytesToMB(row.size) : '-',
        },
        {
            name: 'Action',
            className: "permission-tabe-last-cell",
            cell: (row, index) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        {row.type.toLowerCase() === "folder" ?
                            <button
                                className="btn btn-primary rounded-circle ml-1"
                                title="Folder Content"
                                onClick={() => { handleFolderClick(row.id) }}
                            >
                                <i className="fas fa-folder"></i>

                            </button> : <button
                                className="btn btn-primary rounded-circle ml-1"
                                title="Add"
                                data-dismiss="modal"
                                onClick={() => { handleBrowseAMI(row.file) }}
                            >
                                <i className="fal fa-plus"></i>

                            </button>
                        }
                        {row.download_link && (
                            <button
                                className="btn btn-primary rounded-circle ml-1"
                                title="Download"
                                onClick={() => {
                                    window.location.href = row.download_link;
                                }}
                            >
                                <i className="fal fa-download"></i>
                            </button>
                        )}
                        {row.download_link && row.webUrl && (
                            <button
                                className="btn btn-primary rounded-circle ml-1"
                                title="View"
                                onClick={() => {
                                    window.open(row.webUrl, '_blank');
                                }}
                            >
                                <i className="fal fa-eye"></i>
                            </button>
                        )}
                    </div>
                </div>
            ),
        },
    ]);

    const handleBackButtonClick = async () => {
        try {
            setLoading(true);
            const updatedFolderPath = [...currentFolderPath];
            updatedFolderPath.pop(); // Remove the last object from currentFolderPath
            const folderId = updatedFolderPath.length > 0 ? updatedFolderPath[updatedFolderPath.length - 1].id : null;
            setCurrentFolderPath(updatedFolderPath);
            if (folderId !== null) {
                let response;
                if (isGDC) {
                    response = await getSharepointFolderContents(folderId, '&location=GDC');
                } else {
                    response = await getSharepointFolderContents(folderId);
                }
                setData(response.data);
                setOriginalData(response.data);
            } else {
                let response;
                if (isGDC) {
                    response = await getSharepointFolderContents(GDC_BRIEF_FOLDER_ID, '&location=GDC');
                } else {
                    response = await getSharepointFolderContents(AIE_BRIEF_FOLDER_ID);
                }
                setData(response.data);
                setOriginalData(response.data);
            }
        } catch (error) {
            console.error('Error fetching folder data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePerRowsChange = (newPerPage) => {
        setState((prevState) => ({ ...prevState, perPage: newPerPage }));
    };

    useEffect(() => {
        handleTableScroll();
    }, [loading]);

    const renderNestedFolderHierarchy = (folders) => {
        const startIndex = folders.length > 3 ? folders.length - 3 : 0; // Calculate the start index for the last three folders
        const lastThreeFolders = folders.slice(startIndex); // Get the last three folders or all folders if there are less than three
        const folderElements = lastThreeFolders.map((folder, index) => {
            const folderSpace = Array((index + 1) * 3).fill('\u00A0').join(''); // Calculate space for the current folder based on its index and start index
            return (
                <>
                    <div className="partition-line"></div>
                    <div className='sharepoint-tab-button' key={index} style={index === currentFolderPath.length - 1 ? { backgroundColor: '#ddd' } : { backgroundColor: '#f0f0f0' }}>
                        <span>{folderSpace}</span>
                        <i className="fas fa-folder" style={{ color: 'steelblue', marginRight: '10px', fontSize: '17px' }}></i>
                        <a
                            href="#"
                            style={{ fontSize: '14px', color: 'black' }}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSidebarClick(folder.id);
                            }}
                        >
                            <span style={{ wordWrap: 'anywhere', fontSize: '13px' }}>{folder.name}</span>
                        </a>
                    </div>
                </>
            );
        });

        return folderElements;
    };


    return (
        <div className="sharepoint-file-upload-container">
            <div className="sidebar" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                <h6>Storage Locations</h6>
                <div className="nested-folder-list">
                    <div
                        className='sharepoint-tab-button'
                        onClick={() => { setIsGDC(false); setRootClick(true); }}
                        style={!isGDC && currentFolderPath.length === 0 ? { backgroundColor: '#ddd' } : { backgroundColor: '#f0f0f0' }}
                    >
                        <i className="fab fa-microsoft" style={{ color: '#0078D3', marginRight: '10px', fontSize: '20px' }}></i>
                        <span style={{fontSize: '13px'}}>AIE Sharepoint </span>
                    </div>
                    {!isGDC && currentFolderPath.length > 0 && renderNestedFolderHierarchy(currentFolderPath)}
                    <div className="partition-line"></div>
                    <div
                        className='sharepoint-tab-button'
                        onClick={() => { setIsGDC(true); setRootClick(true); }}
                        style={isGDC && currentFolderPath.length === 0 ? { backgroundColor: '#ddd' } : { backgroundColor: '#f0f0f0' }}
                    >
                        <i className="fab fa-microsoft" style={{ color: '#A0B40F', marginRight: '10px', fontSize: '20px' }}></i>
                        <span style={{fontSize: '13px'}}>GDC Sharepoint</span>
                    </div>
                    {isGDC && currentFolderPath.length > 0 && renderNestedFolderHierarchy(currentFolderPath)}
                </div>
            </div>

            <div className="modal-body my-tickets-info-list Tickets-main-wrap tickets-new-custom" style={{ maxHeight: '600px' }}>
                <div className="filter-search-icon card card-table-custom roles-table" style={{ border: 'none' }}>
                    {currentFolderPath.length > 0 && (<div className='search-filter-div'>
                        <div className="navigation-bar mb-4 search-filter-div-left" style={{ maxWidth: '1000px' }}>
                            <i className="fas fa-folder-tree" style={{ color: 'steelblue', marginRight: '10px' }}></i>
                            {isGDC ? (
                                <>
                                    <span className="bold" style={{ color: 'steelblue' }}>GDC Sharepoint</span>
                                    {currentFolderPath.length > 2 && <span>{'  > ...'}</span>}
                                    {currentFolderPath.slice(-2).map((folder, index) => (
                                        <span key={index} title={folder.name}>&nbsp;&nbsp;{'>'}&nbsp;&nbsp;{folder.name.length > 20 ? folder.name.substring(0, 20) + '...' : folder.name}</span>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <span className="bold" style={{ color: 'steelblue' }}>AIE Sharepoint</span>
                                    {currentFolderPath.length > 2 && <span title={currentFolderPath.map(folder => folder.name).join(' > ')}>{' > ...'}</span>}
                                    {currentFolderPath.slice(-2).map((folder, index) => (
                                        <span key={index} title={folder.name}>&nbsp;&nbsp;{'>'}&nbsp;&nbsp;{folder.name.length > 20 ? folder.name.substring(0, 20) + '...' : folder.name}</span>
                                    ))}
                                </>
                            )}
                        </div>

                        <div className='search-filter-div-right'>
                            <button
                                onClick={() => handleBackButtonClick()}
                                title="Back"
                                className="btn btn-white-bordered"
                            >
                                {GlobalIconNames("leftarrow")}Back
                            </button>
                        </div>
                    </div>
                    )}

                    <div className="search-filter-div">
                        <div className="search-filter-div-left">
                            <div className="system-administration-table table-responsive">
                                <div className="table-responsive-div">
                                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                        <div id="assessment-table-main_filter" className="dataTables_filter">
                                            <label>
                                                <input
                                                    type="search"
                                                    className=""
                                                    placeholder="Search"
                                                    aria-controls="assessment-table-main"
                                                    onChange={handleSearchChange}
                                                    value={search} />
                                            </label>
                                            <div className="filter-eff filter-data-btn">
                                                <button className="filter-buttons">
                                                    {GlobalIconNames("filter")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showNewFolderInput && (
                            <div className="d-flex" style={{width : '30%'}}>
                                <input
                                    type="text"
                                    value={newFolderName}
                                    onChange={(e) => setNewFolderName(e.target.value)}
                                    placeholder="Folder Name"
                                    className='form-control cursor-pointer mr-2'
                                    style={{height : '34px'}}
                                />
                                <div className='d-flex'>
                                    <button className="btn btn-primary rounded-circle"
                                        title="Add" onClick={handleCreateFolder}>
                                        <i className="fal fa-plus"></i>
                                    </button>
                                    <button
                                        className="btn btn-primary rounded-circle ml-1 mr-2"
                                        title="Cancel"
                                        onClick={toggleNewFolderInput}
                                    >
                                        <span><i className="fal fa-times"></i></span>
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="search-filter-right">
                            <button
                                className="btn-primary btn-white-bordered"
                                title="Add Folder"
                                onClick={toggleNewFolderInput}
                            >
                                <i className="fas fa-folder"></i> Add Folder
                            </button>
                            <input
                                id="fileInput"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange} />
                            <button
                                className="btn btn-white-bordered ml-1"
                                onClick={() => {
                                    document.getElementById('fileInput').click();
                                }}
                                title="Upload File"
                            >
                                <i className="fas fa-paperclip"></i> Upload File
                            </button>
                        </div>
                    </div>
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <DataTable
                            paginationDefaultPage={state.page}
                            data={data}
                            progressPending={loading}
                            progressComponent={<SkeletonTicketList />}
                            columns={columns}
                            pagination={false}
                            noDataComponent={Str.noRecord}
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            highlightOnHover={false}
                            paginationPerPage={state.perPage}
                            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                            {...otherTableProps} />
                        {bottomButton.map((item, index) => (
                            <div key={index}>{item}</div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default forwardRef(SharepointFileUpload);
