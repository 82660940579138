import { useEffect } from "react";
// import { GetCategoryDetail } from "../../../services/StudentService";
import "jspdf-autotable";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useState, Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw, ContentState, Modifier, Entity, AtomicBlockUtils, RichUtils } from "draft-js";
import PropTypes from 'prop-types';
import { BlockPicker } from 'react-color';
import { CKEditor } from 'ckeditor4-react';
import HtmlParser from "react-html-parser";
import { API_BASE, DOMAIN_COLOR, IMAGE_URL } from "../../utils/Constants";
import FileSystem from "./FileSystem";
import PopupComponent from "./PopupComponent";
import $ from "jquery";
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';

class ColorPic extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hexColor: "#000",
      expanded: false
    }
    this.handleExpand = this.handleExpand.bind(this);
  }


  static propTypes = {
    onExpandEvent: PropTypes.func,
    onChange: PropTypes.func,
    currentState: PropTypes.object,
  };

  onChange = (color) => {
    this.setState({ hexColor: color.hex });
    const { onChange, editorState } = this.props;
    // const { editorState, onChange } = this.props;
    // RichUtils.toggleInlineStyle(
    //       editorState,
    //       'CODE'
    //     )
    onChange('color', color.hex);


    // const newState = RichUtils.toggleInlineStyle( editorState, 'CODE');
    // const { editorState, onChange } = this.props;
    //   const newState = RichUtils.toggleInlineStyle(
    //     editorState,
    //     'CODE'
    //   );
    //   if (newState) {
    //     onChange(newState);
    //   }
  }

  handleExpand() {
    this.setState({ expanded: !this.state.expanded });
  }

  stopPropagation = (event) => {
    event.stopPropagation();
  };

  renderModal = () => {
    // const { color } = this.props.currentState;
    return (
      <div
        className="rdw-link-modal custom-picker"
        onClick={this.stopPropagation}
      >
        <BlockPicker color={this.state.hexColor} onChangeComplete={this.onChange} />
      </div>
    );
  };

  render() {
    const { expanded, onExpandEvent } = this.props;
    return (
      <div className="rdw-link-wrapper" aria-haspopup="true" aria-label="rdw-color-picker" >
        <div className="rdw-option-wrapper" onClick={this.handleExpand} >
          <img src={'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTQuNDA2LjU4NWExLjk5OCAxLjk5OCAwIDAgMC0yLjgyNSAwbC0uNTQuNTRhLjc0MS43NDEgMCAxIDAtMS4wNDggMS4wNDhsLjE3NS4xNzUtNS44MjYgNS44MjUtMi4wMjIgMi4wMjNhLjkxLjkxIDAgMCAwLS4yNjYuNjAybC0uMDA1LjEwOHYuMDAybC0uMDgxIDEuODI5YS4zMDIuMzAyIDAgMCAwIC4zMDIuMzE2aC4wMTNsLjk3LS4wNDQuNTkyLS4wMjYuMjY4LS4wMTJjLjI5Ny0uMDEzLjU3OS0uMTM3Ljc5LS4zNDdsNy43Ny03Ljc3LjE0Ni4xNDRhLjc0Ljc0IDAgMCAwIDEuMDQ4IDBjLjI5LS4yOS4yOS0uNzU5IDAtMS4wNDhsLjU0LS41NGMuNzgtLjc4Ljc4LTIuMDQ0IDAtMi44MjV6TTguNzk1IDcuMzMzbC0yLjczLjUxNSA0LjQ1Mi00LjQ1MiAxLjEwOCAxLjEwNy0yLjgzIDIuODN6TTIuMDggMTMuNjczYy0xLjE0OCAwLTIuMDguMjk1LTIuMDguNjYgMCAuMzYzLjkzMi42NTggMi4wOC42NTggMS4xNSAwIDIuMDgtLjI5NCAyLjA4LS42NTkgMC0uMzY0LS45My0uNjU5LTIuMDgtLjY1OXoiLz48L2c+PC9zdmc+'} alt="" />
        </div>
        {this.state.expanded ? this.renderModal() : undefined}
      </div>
    );
  }
}

function HtmlInputEditor({ editorState, setEditorState, initialValue,readOnly, hideSign, belowNoMargin, isCKEditor, isInvalid = false , from, updateOptions , cameFrom}) {
  let uniqueId = Math.floor(Math.random()*100);
  const [isFMShowen, setIsFMShowen] = useState(false)
  const [selectedValue, setSelectedValue] = useState();
  const [showCkEditor, setShowCkEditor] = useState(true);
  const [tempEditorState, setTempEditorState] = useState(false);
  const [loading, setLoading] = useState(false)

  // const isInvalid = false;
  const onEditorStateChange = (editorState) => setEditorState(editorState);

  // useEffect(() => { }, []);

  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // function uploadImageCallBack(file) {
  //   return new Promise((resolve, reject) => {
  //     getBase64(file)
  //       .then((data) => {
  //         // console.log("returned file in base64 is ", data);
  //         resolve({ data: { link: data } });
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }

  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      getBase64(file)
        .then((data) => {
          resolve({ data: { link: data } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const handlePastedFiles = async (files) => {
    getBase64(files[0])
      .then((data) => {
        setEditorState(insertImage(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const insertImage = (url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  const styleMap = {
    'CODE': {
      color: `#ffffff`
    },
  };

  // useEffect(() => {
  //   let htmlTag
  //   if (selectedValue) {
  //     htmlTag = `<iframe frameborder="0" scrolling="auto" src="${IMAGE_URL}/${selectedValue}"></iframe>`
  //   }
  //   setTempEditorState(editorState + (htmlTag ? htmlTag : ""))
  // }, [editorState, selectedValue])
  
  useEffect(() => {
    const updateData = async () => {
      if (selectedValue) {
        setShowCkEditor(false)
        let htmlTag = ``
        let extension = selectedValue.split('.').pop().toLowerCase()
        // htmlTag = `<video src="https://youtu.be/qhd0Ce4Rsyk" width="640" height="480" controls></video>\r\n`
        // htmlTag = `<div data-oembed-url="https://www.youtube.com/embed/5rIeFZAlovs">
        // <div>
        // <div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;"><iframe width="560" height="315" src="https://www.youtube.com/embed/5rIeFZAlovs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        // </div>
        // </div>`
    //     htmlTag = `<object data="${IMAGE_URL}/${selectedValue}" width="600" height="400">
    //     <embed src="${IMAGE_URL}/${selectedValue}" width="600" height="400"> </embed>
    //     Error: Embedded data could not be displayed.
    // </object>`
        // htmlTag = `<embed src="https://www.youtube.com/embed/5rIeFZAlovs"
        // width=200
        // height=200
        // onerror="alert('URL invalid !!');" />`
// last working stage with audio auto play bug
        // htmlTag = `<iframe frameborder="0" scrolling="auto" src="${IMAGE_URL}/${selectedValue}"></iframe>`
        if (['mp3', 'wav', 'ogg', 'aac'].includes(extension)) {
          htmlTag = `<iframe frameborder="0" scrolling="no" src="https://${DOMAIN_COLOR}/ckEditorTags.html?path=${IMAGE_URL}/${selectedValue}&type=audio"></iframe>` // for live
          // htmlTag = `<iframe frameborder="0" scrolling="no" src="http://localhost:3000/ckEditorTags.html?path=${IMAGE_URL}/${selectedValue}&type=audio"></iframe>` // for local host
          // htmlTag = `<iframe frameborder="0" scrolling="auto" id="audio"><audio id="myAudio"><source src="${IMAGE_URL}/${selectedValue}" type="audio/mpeg"></audio></iframe>`
          // htmlTag = `<iframe frameborder="0" scrolling="auto" id="audio"><audio id="myAudio"><source src="${IMAGE_URL}/${selectedValue}" type="audio/mpeg"></audio></iframe>`
          // htmlTag = `<iframe frameborder="0" scrolling="auto" id="myAudio" src="${IMAGE_URL}/${selectedValue}"></iframe>`
        } else {
          htmlTag = `<iframe frameborder="0" scrolling="no" src="https://${DOMAIN_COLOR}/ckEditorTags.html?path=${IMAGE_URL}/${selectedValue}&type=video"></iframe>` // for live
          // htmlTag = `<iframe frameborder="0" scrolling="no" src="http://localhost:3000/ckEditorTags.html?path=${IMAGE_URL}/${selectedValue}&type=video"></iframe>` // for local host
          // htmlTag = `<iframe frameborder="0" scrolling="auto" allow="picture-in-picture" src="${IMAGE_URL}/${selectedValue}"></iframe>`
          // htmlTag = `<iframe frameborder="0" scrolling="auto" id="video"><video id="myVideo" ><source src="${IMAGE_URL}/${selectedValue}" type="video/mp4"></video></iframe>`
        }
        // if (['mp3', 'wav', 'ogg', 'aac'].includes(extension)) {
        //   // if (['mp3', 'wav', 'ogg', 'aac', 'mp4', 'webm', 'ogv'].includes(extension)) {
        //     htmlTag = htmlTag + `<audio controls name="media">
        //     <source src="${IMAGE_URL}/${selectedValue}" type="audio/ogg">
        //     Your browser does not support the audio element.
        //     </audio>`
        //   } else {
        //     htmlTag = htmlTag + `<video width="320" height="240" controls name="media">
        //     <source src="${IMAGE_URL}/${selectedValue}" type="video/mp4"></source>
        //     Your browser does not support the video tag.
        //     </video>`
        //   }
        let updateValue = (editorState ? editorState : "") + htmlTag
        if (updateOptions) {
          await updateOptions(updateValue)
          setShowCkEditor(true)
        } else {
          setEditorState(prevState => {
            return (updateValue)
          })
          setShowCkEditor(true)
        }
        // setTempEditorState(updateValue)
        // setSelectedValue()
      }
    }
    if (selectedValue) {
      updateData()
    }
    const cleanUp = async () => {
      let htmlTag = ``
      if (selectedValue) {
        htmlTag = `<iframe frameborder="0" scrolling="auto" src="${IMAGE_URL}/${selectedValue}"></iframe>`
      }
      let updateValue = (editorState ? editorState : "") + htmlTag
      await updateOptions(updateValue)
    }
    return () => {
      if (selectedValue) {
        if (updateOptions) {
          cleanUp()
        } else {
          let htmlTag = ``
          if (selectedValue) {
            htmlTag = `<iframe frameborder="0" scrolling="auto" src="${IMAGE_URL}/${selectedValue}"></iframe>`
          }
          let updateValue = (editorState ? editorState : "") + htmlTag
          setEditorState(updateValue)
        }
      }
    }
  }, [tempEditorState])

  useEffect(() => {
    var audioTag = document.getElementById("myAudio");
    // var videoTag = document.getElementById("myVideo");
    // var iframe = document.getElementById("myAudio");
    // var divs = document.getElementsByClassName("editor-static");
    // var videoid = document.getElementById("video");

    // setTimeout(() => {
      // console.log("asjkgh", videoid)
      // Access the iframe's contentWindow
    // const iframeContentWindow = iframeRef.current.contentWindow;
    

    // Check if iframeContentWindow is null (e.g., due to cross-origin restrictions)
    // if (iframeContentWindow) {
    //   // Access elements inside the iframe and perform manipulations
    //   const iframeDocument = iframeContentWindow.document;
    //   const videoElement = iframeDocument.querySelector('video');

    //   if (videoElement) {
    //     // Manipulate the video element or do other operations
    //     videoElement.play();
    //   }
      // Array.from(divs)[0].firstChild.style = {display: "block", visibility: "unset"}
      // let iframess = Array.from(divs)[0].firstChild.querySelector('iframe')
      // if (iframess) {
        
        // console.log("fsdha", iframess)
      // }
      // const iframeContentWindow = Array.from(divs)[0].firstChild?.contentDocument;
      // const iframeDocument = iframeContentWindow?.top;
      // setTimeout(() => {
      //   if (iframess?.contentDocument) {
      //     iframess.contentDocument.write("<html><head></head><body><div id='foo'>The div</div></body></html>");
      //   }
      //   console.log("afdhasdf", iframeContentWindow, iframess?.contentDocument)
      // }, 500);
      
    // }, 1000);
    // if (iframe) {
    // iframe.onload = function() {
      // Access the iframe's content window
      // const iframeContentWindow = iframe.contentWindow;

      // Access the iframe's document
      // const iframeDocument = iframeContentWindow.document;
      // Find the video tag inside the iframe's document
      // setTimeout(() => {
        
      //   const videoTag = iframeDocument.querySelector('body');
      //   console.log("lakjsdf", Array.from(divs)[0].firstChild.querySelector('iframe'))
      // }, 1000);
      //TODO 
    
      // Now you can manipulate the video tag as needed
      // if (videoTag) {
        // Do something with the video tag
      //   console.log(videoTag.src); // or any other video tag manipulation
      // } else {
      //   console.log('Video tag not found in the iframe.');
      // }
    // };
  // }
    if (audioTag) {
      new Audio(audioTag).pause()
      // audioTag.setAttribute("autoplay", false)
      // console.log("alsdjkg11111111111")
      // audioTag.onPlay(res => {
      //   console.log("alsdjkg>>>>",res)
      // })
    // } else if (videoTag) {
    //   videoTag.pause()
    }
  }, [editorState])

  useEffect(() => {
    if (initialValue && showCkEditor && isCKEditor) {
      // Force re-render to ensure initial state is displayed
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 0);
    }
  }, [initialValue, isCKEditor]);

  if (loading) {
    return ""
  }

  return (
    <div>
      <div className="card-body-inr card-body-info">
        <div>
          <div className={!isInvalid ? ` ${!belowNoMargin ? "mb-4" : ""} editor-static ` : ` ${!belowNoMargin ? "mb-4" : ""} file-req is-invalid  `}>
            {isCKEditor ? 
            <>{showCkEditor ? <CKEditor
                initData={editorState ? HtmlParser(editorState) : ""}
              onChange={({ editor }) => {
                if (updateOptions) {
                  updateOptions(editor.getData())
                  return
                }
                setEditorState(editor.getData());
              }}
              onPaste={({editor}) =>{
                setEditorState(editor.getData())
              }}
                onBlur={({ editor }) => {
                  if (editorState) { 
                    setEditorState(editor.getData());
                  }
              }}
              readOnly={false}
              onBeforeLoad={(CKEDITOR) => {
                let alreadyExist = CKEDITOR.plugins.get(`cstButton${uniqueId}`)
                if (!alreadyExist) {
                  CKEDITOR.plugins.add(`cstButton${uniqueId}`, {
                    icons: `cstButton${uniqueId}`,
                    init: function (editor) {
                      editor.ui.addButton(`CstButton${uniqueId}`, {
                        label: 'Audio/Video',
                        command: `cstButton${uniqueId}`,
                        toolbar: 'insert,10',
                        name: `cstbutton${uniqueId}`,
                        icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaLO2_EFFI-eVhUBi6BS6XKE54viRbADMBb-xvh41zS7Pzk5Ts-fkAHrwIHdfZ2_vYE7g&usqp=CAU',
                      });
              
                      editor.addCommand(`cstButton${uniqueId}`, {
                        exec: function (edt) {
                          // const ancTag = document.createElement('a');
                          // ancTag.target = '_blank';
                          // ancTag.href = '/fileSystem/1';
                          // edt.insertHtml(`<video width="320" height="240" controls>
                          // <source src="${IMAGE_URL}/${selectedValue}" type="video/mp4"></source>
                          // Your browser does not support the video tag.
                          // </video>`
                          // );
                          // ancTag.click();
                          // setEditorState(edt.getData());
                          setSelectedValue(prevState => false)
                          $("#editor-fs").modal("show");
                          setIsFMShowen(!isFMShowen)
                          setShowCkEditor(false)
                        },
                      });
                    },
                  });
                }
                return (CKEDITOR.disableAutoInline = true)
              }}
              onBeforeDestroy={(CKEDITOR) => {
                if(CKEDITOR?.editor?.commands[`cstButton${uniqueId}`]){
                  CKEDITOR.editor.commands[`cstButton${uniqueId}`].disable();
                }
                // CKEDITOR.editor.commands.cstButton.unmark();
                // CKEDITOR.editor.plugins.remove('cstButton');
              }}
              onInstanceReady={({ editor }) => {
                editor.setData(HtmlParser(editorState));
              }}
              config={{
                allowedContent: true,
                entities:false,
                basicEntities: false,
                entities_greek: false, 
                entities_latin: false, 
                entities_additional: '', 
                preset:'full',
                extraPlugins:`forms,iframe,font,colordialog,colorbutton,embed,emoji,justify,bidi,exportpdf,templates,smiley,showblocks,selectall,scayt,find,entities,basicstyles,popup,uploadimage,codesnippet,cstButton${uniqueId},${ (from == "quizManagement" || from == "multiFormatCKEditor") ? 'mathjax' : ''}`,
                uploadUrl:`${API_BASE}/uploadEditorFiles`,
                // extraPlugins:'dialog',
                // toolbar: 'full',
                //below code is to run videos in ckeditor
                embed_provider:'//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
                font_names: 'default;Arial;Comic Sans MS;Courier New;Georgia;Lucida Sans Unicode;Tahoma;Times New Roman;Trebuchet MS;Verdana;Segeo UI Light;',
                filebrowserUploadUrl:`${API_BASE}/uploadEditorFiles`,
                // filebrowserBrowseUrl:`https://www.myaie.ac/cadcocms/fileman/index.html` // add url for file system on browse button click 
                // filebrowserBrowseUrl:`${API_BASE}/cadcocms/fileman/index.html` // add url for file system on browse button click 
                mathJaxLib: '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-AMS_HTML',
                filebrowserBrowseUrl:`/fileSystem`, // to open file system on browse button click
                versionCheck: false ,
                toolbar: cameFrom === 'CTA_section' ? [
                  { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
                  { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
                  { name: 'undo', items: ['Undo', 'Redo'] },
                  { name: 'links', items: ['Link'] }
                ] : 'Full', // Modify toolbar based on cameFrom prop
                toolbarGroups: cameFrom === 'CTA_section' ? [
                  { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                  { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                  { name: 'insert' }
                ] : null,
              }}
              onFileUploadRequest={async function( evt ) {
                var fileLoader = evt.data.fileLoader,
                    formData = new FormData(),
                    xhr = fileLoader.xhr;
                // let userToken = await localStorage.getItem("token") // user authorization
                // console.log("lkjlkiono", userToken)
                // xhr.setRequestHeader( 'Authorization', `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjQ1NCwiaWF0IjoxNjc5NjMzNjIxLCJleHAiOjE2Nzk2Njk2MjF9.y5lLd6r7GLv6-VHwK6CGtYv2CQltOtGnJWNMYmcs7VA` );
                xhr.open( 'POST', fileLoader.uploadUrl, true );
                formData.append( 'document', fileLoader.file, fileLoader.fileName);
                fileLoader.xhr.send( formData );
            
                // Prevented the default behavior.
                evt.stop();
            }}
            // onWidgetDefinition={function(ev) {  
            //   //dialogDefinition is a ckeditor event it's fired when ckeditor dialog instance is called  
            //   var dialogName = ev.data.name;  
            //   var dialogDefinition = ev.data.definition;  
            //   if (dialogName == 'image') { //dialogName is name of dialog and identify which dialog is fired.  
            //       var infoTab = dialogDefinition.getContents('info'); // get tab of the dialog  
            //       var browse = infoTab.get('browse'); //get browse server button  
            //       browse.onClick = function() {  
            //           //here we can invoke our custom fileuploader or server files popup  
            //           alert('open your file uploader or server files popup');  
            //       };  
            //   }  }}
            //   onSelectionChange={function(ev) {  
            //     //dialogDefinition is a ckeditor event it's fired when ckeditor dialog instance is called  
            //     var dialogName = ev.data.name;  
            //     var dialogDefinition = ev.data.definition;  
            //     if (dialogName == 'image') { //dialogName is name of dialog and identify which dialog is fired.  
            //         var infoTab = dialogDefinition.getContents('info'); // get tab of the dialog  
            //         var browse = infoTab.get('browse'); //get browse server button  
            //         browse.onClick = function() {  
            //             //here we can invoke our custom fileuploader or server files popup  
            //             alert('open your file uploader or server files popup');  
            //         };  
            //     }  }}
            onFileUploadResponse={function( evt ) {
              // Prevent the default response handler.
              evt.stop();
          
              // Get XHR and response.
              var data = evt.data,
                  xhr = data.fileLoader.xhr,
                  response = xhr.responseText.split( '|' );
                  response[0] = IMAGE_URL + response[0].replace("home/myaie/public_html/", "").replace("public", "");
          
              if ( response[ 1 ] ) {
                  // An error occurred during upload.
                  data.message = response[ 1 ];
                  evt.cancel();
              } else {
                  data.url = response[ 0 ];
              }
              setEditorState(evt.editor.getData())
            } }
            /> : "" }
            <PopupComponent
            id="editor-fs"
            type="stateBasedCondition"
            header={{
              iconName:"fa-plus",
              heading: "File System"
            }}
            renderCondition={!showCkEditor}
            setRenderCondition={setShowCkEditor}
            componentToRender = {<FileSystem setIsFMShowen={setIsFMShowen} isFMultiMedia={true} setSelectedValue={setSelectedValue} setTempEditorState={setTempEditorState} tempEditorState={tempEditorState} />}
            /> </>
            : <Editor
              readOnly={readOnly}
              defaultEditorState={editorState}
              editorState={editorState}
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
              handlePastedFiles={handlePastedFiles}
              toolbar={{
                image: { urlEnabled: true, uploadEnabled: true, uploadCallback: uploadImageCallBack },
                fontFamily: {
                  options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Segoe UI Light'],
                },
                colorPicker: { component: ColorPic },
              }}
              customStyleMap={styleMap}
            />}
            {!hideSign && <div className="editor-static-blk">
              <h4></h4>
              <div className="editor-static-blk-info">
                <p>Kind Regards,</p>
                <br></br>
                <p>myAIE Student Support Team</p>
                <p>students@myaie.ac</p>
                <p>www.myaie.ac</p>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HtmlInputEditor;
