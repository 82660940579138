import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

/** HOC */
import PublicRoute from "./pages/PublicRoute";
import PrivateRoute from "./pages/PrivateRoute";
import ResetPassword from "./pages/ResetPassword";
import NewResetPassword from "./pages/NewResetPassword";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
// import ProfilePage from "./pages/ProfilePage";
import NewProfilePage from "./pages/NewProfilePage";
import Test from "./pages/Test";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StudentVerify from "./pages/StudentVerify";
import ViewStudent from "./pages/ViewStudent";
import Settings from "./pages/Settings";
import StudentStatus from "./pages/StudentStatus";
import SystemAdministration from "./pages/SystemAdministration";
import AddNewUser from "./components/systemadministration/usermanagement/AddNewUser";
import EditUser from "./components/systemadministration/usermanagement/EditUser";
import PermissionManagement from "./pages/PermissionManagement";
import NoAccess from "./components/common/NoAccess";
import ProgramManagementCenter from "./pages/ProgramManagementCenter";
import Modules from "./components/pmc/modules/Modules";
import ModuleDetail from "./components/pmc/modules/ModuleDetail";
import ProgramDetail from "./components/pmc/program/ProgramDetail";
import Programs from "./pages/Programs";
import Appeals from "./pages/Appeals";

import Courses from "./pages/Courses";
import DetailCourses from "./components/courses/DetailCourses";
import BookedCourses from "./components/courses/BookedCourses";
import OpenCourseBookings from "./components/courses/OpenCourseBookings";
import ContentAssemblies from "./components/courses/ContentAssemblies";
import AssembliesDetails from "./components/courses/ContentAssembliesBlock/AssembliesDetails";
import ResourcePoolDetails from "./components/courses/ContentAssembliesBlock/ResourcePoolDetails";
import SupportAdministration from "./pages/SupportAdministration";
import AddTicket from "./components/supportadministration/AddTicket";
import ViewTicket from "./components/supportadministration/ViewTicket";
import AddNewCourse from "./components/courses/AddNewCourse";
import StudentSupport from "./pages/StudentSupport";
import TabList from "./components/studentsupport/studenthub/TabList";
import AuditTrail from "./components/studentsupport/studenthub/AuditTrail";
import RearrangeTab from "./components/studentsupport/studenthub/RearrangeTab";
import CategoriesTabList from "./components/studentsupport/studenthub/CategoriesTab";
import AuditTrailBrand from "./components/systemadministration/BrandManagement/AuditTrail";
import AddNewBrand from "./components/systemadministration/BrandManagement/AddNewbrand";
import AddPortalSetting from "./components/systemadministration/BrandManagement/AddPortalSetting";
import EditBrand from "./components/systemadministration/BrandManagement/EditBrand";

import EditPortalSetting from "./components/systemadministration/BrandManagement/EditPortalSetting";
import BrandManagement from "./pages/BrandManagement";
import ResourcesTabList from "./components/studentsupport/studenthub/ResourcesTab";
import DetailTab from "./components/systemadministration/BrandManagement/DetailTab";
import VisualsTab from "./components/systemadministration/BrandManagement/VisualsTab";
import SingleAuditTrailBrand from "./components/systemadministration/BrandManagement/SingleAuditTrail";
import SubCategoryDetails from "./components/courses/ContentAssembliesBlock/SubCategoryDetails";
import AddQuestion from "./components/courses/smartAssessment/questionBank/AddQuestion";
import ResourcePoolSubCatDetails from "./components/courses/ContentAssembliesBlock/ResourcePoolSubCatDetails";

import NetsuiteManagement from "./pages/NetsuiteManagement";
import EmailManagement from "./pages/EmailManagement";

import Notification from "./pages/Notification";
import General from "./pages/General";
import AuditTrailGeneral from "./components/systemadministration/General/AuditTrail";
import ServerManagement from "./pages/ServerManagement";
import ClassManagement from "./pages/ClassManagement";
import CampusManagement from "./pages/CampusManagement";

import TicketSetting from "./pages/TicketSetting";

import AddAssessment from "./components/courses/smartAssessment/assessment/AddAssessment";
import LiveChat from "./components/studentsupport/studenthub/LiveChat";

import StudentAdministration from "./pages/StudentAdministration";
import ReRegistration from "./pages/ReRegistration";
import BookingManagement from './components/campusAdministration/bookingManagement/BookingManagement'
import ScheduleManagement from './components/campusAdministration/scheduleManagement/ScheduleManagement';

import Phone from "./pages/Phone";
import BannerPages from "./pages/BannerPages";
import DashBoardDesign1 from "./components/courses/DetailCoursesBlocks/DashBoardDesign1";
import CoursesDeonDesign from "./pages/CoursesDeonDesign";
import CoursesDeonDesignUnknown from "./pages/CoursesDeonDesignUnknown";
import ResourceManagement from "./pages/resourceManagement";
import { ExportPDF } from "./components/pmc/program/ExportPDF";
import LandingPage from "./components/common/LandingPage";
import ReArrangeTab from "./components/studentsupport/studenthub/RearrangeTab";
import CreateTab from "./components/studentsupport/studenthub/CreateTab";
import TabDetail from "./components/studentsupport/studenthub/TabDetail";
import DuplicateQuestion from "./components/courses/smartAssessment/questionBank/DuplicateQuestion";
import StudentHubManagement from "./components/studentsupport/studenthub";
import ExternalExamReg from "./pages/ExternalExamReg";
import { GetBrandColor } from "./services/ConfigServices";
import { connect } from "react-redux";
import { setBrandVisuals } from "./store/actions";
import BrandSetting from "./pages/BrandSetting";
import ClassReport from "./pages/ClassReport";
import { DOMAIN_COLOR } from "./utils/Constants";
import FileSystem from "./components/common/FileSystem";
import TemplateTable from "./pages/TemplateTable";
import CertificateTemplates from "./components/StudentAdministration/CertificateTemplates/CertificateTemplate";
import AddCertificateTemplate from "./components/StudentAdministration/CertificateTemplates/AddCertificateTemplate";
import ProgramArticulations from "./pages/ProgramArticulations";
import IntakeCopy from "./pages/IntakeCopy";
import StudentCourseGradeBook from "./pages/StudentCourseGradeBook";
// import RepositionElements from "./components/StudentAdministration/CertificateTemplates/RepositionElement";
import Repositioning from "./components/StudentAdministration/CertificateTemplates/Repositioning";
import ViewCertificateTemplate from "./components/StudentAdministration/CertificateTemplates/ViewCertificateTemplate";
import DuplicateTemplate from "././components/StudentAdministration/CertificateTemplates/DuplicateTemplate";
import GradeMix from "./pages/GradeMix";
import SoftphoneManagement from './pages/SoftphoneManagement';
import CertificateTemplate from "./pages/CertificateTemplate";
import Website from "./pages/WebsiteManagement";
import CreateBrandTemplate from "./components/website/addbrandtemplate/CreateBrandTemplate";
import AddWebsiteList from "./components/website/websitelist/AddWebsiteList";
import Events from "./pages/Events";
import LayoutNavigation from "./pages/LayoutNavigation";
import LayoutMenusCreate from "./components/layoutNavigation/layoutMenus/LayoutMenusCreate";
import StaticPages from "./pages/StaticPages";
import CreateStaticPages from './components/pages/staticPages/CreateStaticPages';
import CampusPages from './pages/CampusPages';
import CreateCampusPages from './components/pages/campusPages/CreateCampusPages';
import HeaderTabs from "./components/layoutNavigation/layoutHeaders/HeaderTabs";
import LandingPageTabs from "./components/website/events/landingPageItem/LandingPageTabs";
import PopupsPages from "./pages/PopupsPages";
import CreatePopupsPages from "./components/pages/popupsPages/CreatePopupsPages";
import CategoryLandingpages from "./pages/CategoryLandingpages";
import CreateCategoryLandingPages from "./components/pages/CategoryLandingpages/CreateCategoryLandingPages";
import UpcomingEventsTabs from "./components/website/events/upcomingEventsItem/UpcomingEventsTabs";
import InfoPacksTabs from "./components/website/events/infoPacksItem/InfoPacksTabs";
import CategoryTabs from "./components/layoutNavigation/layoutCategories/CategoryTabs";
import CreateLayoutTestimonial from "./components/layoutNavigation/layoutTestimonials/createTestimonial/CreateLayoutTestimonial";
import CourseLandingPages from "./pages/CourseLandingPages";
import CreateCourseLandingPages from "./components/pages/CourseLandingpages/CreateCourseLandingPages";
import BlogAndNews from "./pages/BlogAndNews";
import CreateBlogAndNewsPages from "./components/pages/blogandnews/CreateBlogAndNewsPages";
import EmailsPages from "./pages/EmailsPages";
import CreateEmailsPages from "./components/emailspages/CreateEmailsPages";
import ProgrammeShortCourse from "./components/website/programmeAndShortCourse/ProgrammeShortCourse";
import PartnerManagement from "./pages/PartnerManagement";
import PartnerDetails from "./components/website/partners/PartnerDetails";
import AuditTrailPartner from "./components/website/partners/AuditTrails"
/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setBrandVisuals: (payload) => dispatch(setBrandVisuals(payload)),
  };
}

/** map state */

const mapStateToProps = (state) => ({
  brandVisualsConfig: state.brandVisuals.config,
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expired: false,
    };
    console.warn = () => { };
  }
  componentDidMount() {
    // if(Object.keys(this.props.brandVisualsConfig).length){
    //   document.body.style.setProperty("--topbar-color", this.props.brandVisualsConfig.topbar_color);
    //   document.body.style.setProperty("--highlight-color", this.props.brandVisualsConfig.highlight_color);
    //   document.body.style.setProperty("--sidebar-color", this.props.brandVisualsConfig.sidebar_color);
    //   document.body.style.setProperty("--hover-color", this.props.brandVisualsConfig.hover_color);
    //   document.body.style.setProperty("--active-color", this.props.brandVisualsConfig.active_color);
    //   document.body.style.setProperty("--login-header-color", this.props.brandVisualsConfig.login_header_color);
    //   document.body.style.setProperty("--button-color", this.props.brandVisualsConfig.button_color);
    //   document.body.style.setProperty("--button-hover-color", this.props.brandVisualsConfig.button_hover_color);
    //   document.body.style.setProperty("--button-active-color", this.props.brandVisualsConfig.button_active_color);
    // }else{
    this.GetConfigurations();
    // }
  }

  hexToRgba = (hex, alpha) => {
    const parsedHex = hex.replace("#", "");
    const r = parseInt(parsedHex.substring(0, 2), 16);
    const g = parseInt(parsedHex.substring(2, 4), 16);
    const b = parseInt(parsedHex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  GetConfigurations() {
    let domain = window.location.hostname;
    if (domain == "localhost") {
      domain = DOMAIN_COLOR;
    }
    const parameter = {
      domain: domain,
    };
    GetBrandColor(parameter)
      .then((res) => {
        if (res.status === 200) {
          const resp = res.data;
          // console.log(res.data);
          this.props.setBrandVisuals(resp);
          if (Object.keys(this.props.brandVisualsConfig).length) {
            const rgbaTopbarColor = this.hexToRgba(
              this.props.brandVisualsConfig.topbar_color,
              0.2
            );
            const rgbaSidebarColor = this.hexToRgba(
              this.props.brandVisualsConfig.sidebar_color,
              0.2
            );
            document.body.style.setProperty(
              "--topbar-color",
              this.props.brandVisualsConfig.topbar_color
            );
            document.body.style.setProperty(
              "--topbar-opacity-color",
              rgbaTopbarColor
            );
            document.body.style.setProperty(
              "--highlight-color",
              this.props.brandVisualsConfig.highlight_color
            );
            document.body.style.setProperty(
              "--sidebar-color",
              this.props.brandVisualsConfig.sidebar_color
            );
            document.body.style.setProperty(
              "--sidebar-opacity-color",
              rgbaSidebarColor
            );
            document.body.style.setProperty(
              "--hover-color",
              this.props.brandVisualsConfig.hover_color
            );
            document.body.style.setProperty(
              "--active-color",
              this.props.brandVisualsConfig.active_color
            );
            document.body.style.setProperty(
              "--login-header-color",
              this.props.brandVisualsConfig.login_header_color
            );
            document.body.style.setProperty(
              "--button-color",
              this.props.brandVisualsConfig.button_color
            );
            document.body.style.setProperty(
              "--button-hover-color",
              this.props.brandVisualsConfig.button_hover_color
            );
            document.body.style.setProperty(
              "--button-active-color",
              this.props.brandVisualsConfig.button_active_color
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    return (
      <Router>
        <ToastContainer />
        <Switch>
          <PrivateRoute
            exact
            path="/links/:page/:subPage?"
            component={LandingPage}
          />
          <PrivateRoute
            exact
            path="/support/studentBanners/:type/:id?"
            component={BannerPages}
          />
          <PublicRoute exact path="/" component={LoginPage} />
          <PublicRoute exact path="/forgotPassword" component={ResetPassword} />
          <PublicRoute
            exact
            path="/resetPassword/:id"
            component={NewResetPassword}
          />
          <PublicRoute
            exact
            path="/verify/:studentId"
            component={StudentVerify}
          />
          <PublicRoute
            exact
            path="/viewStudent/:email/:password"
            component={ViewStudent}
          />
          <PrivateRoute exact path="/noaccess" component={NoAccess} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/test" component={Test} />
          <PrivateRoute exact path="/home/:tab?/:type?" component={HomePage} />
          <PrivateRoute exact path="/administration/phone" component={Phone} />
          {/* <PrivateRoute exact path="/profile" component={ProfilePage} /> */}
          <PrivateRoute exact path="/profile" component={NewProfilePage} />
          {/* <PrivateRoute exact path="/website_management" component={Website} /> */}
          <PrivateRoute
            exact
            path="/administration"
            component={SystemAdministration}
          />
          <PrivateRoute
            exact
            path="/systemAdministration/userManagement/:tab/:id?/:subTab?/:type?"
            component={SystemAdministration}
          />
          <PrivateRoute
            exact
            permission="bmview"
            path="/administration/brandSetting/list"
            component={BrandManagement}
          />
          <PrivateRoute
            exact
            permission="bmview"
            path="/administration/brandSetting/auditTrail"
            component={AuditTrailBrand}
          />
          <PrivateRoute
            exact
            permission="bmview"
            path="/administration/brandSetting/singleAuditTrail/:id"
            component={SingleAuditTrailBrand}
          />
          <PrivateRoute
            exact
            permission="bmadd"
            path="/administration/brandSetting/addNewBrand"
            component={AddNewBrand}
          />
          <PrivateRoute
            exact
            permission="bmadd"
            path="/administration/brandSetting/addPortalSetting/:id"
            component={AddPortalSetting}
          />
          <PrivateRoute
            exact
            path="/administration/brandSetting/editBrand/:id"
            component={EditBrand}
          />
          <PrivateRoute
            exact
            permission="bmadd"
            path="/administration/brandSetting/addPortalSetting/detailsTab/:id"
            component={DetailTab}
          />
          <PrivateRoute
            exact
            permission="bmadd"
            path="/administration/brandSetting/addPortalSetting/visualsTab/:id"
            component={VisualsTab}
          />
          <PrivateRoute
            exact
            path="/administration/brandSetting/editPortalSetting/detailsTab/:id"
            component={DetailTab}
          />
          <PrivateRoute
            exact
            path="/administration/brandSetting/editPortalSetting/visualsTab/:id"
            component={VisualsTab}
          />
          {/* DO NOT DELETE THIS ROUTE */}
          {/* <PrivateRoute
            exact
            permission="bmview"
            path="/:sideTab/:module/:tab/:id?"
            component={BrandSetting}
          /> */}
          <PrivateRoute exact path="/addnewuser" component={AddNewUser} />
          {/* <PrivateRoute exact permission="umadd" path="/addnewuser" component={AddNewUser} /> */}
          {/* <PrivateRoute exact path="/systemAdministration/userManagement/:tab/:id?/:subTab?" component={SystemAdministration} /> */}
          <PrivateRoute
            exact
            path="/systemAdministration/permissionManagement/:tab/:id?/:subTab?"
            component={PermissionManagement}
          />
          <PrivateRoute
            exact
            path="/systemAdministration/:sideTab/:tab/:id?/:subTab?"
            component={SystemAdministration}
          />
          {/* <PrivateRoute exact path="/addnewuser" component={AddNewUser} /> */}
          <PrivateRoute
            exact
            permission="umadd"
            path="/addnewuser"
            component={AddNewUser}
          />
          <PrivateRoute exact path="/edituser" component={EditUser} />
          {/* <PrivateRoute exact path="/administration/student-hub" component={StudentSupport} /> */}
          <PrivateRoute
            exact
            // permission="shview"
            path="/support/student-hub-library/student-hub/:page"
            component={StudentHubManagement}
          />
          <PrivateRoute
            exact
            path="/support/student-hub-library/student-hub/open/:id"
            component={TabDetail}
          />
          <PrivateRoute
            exact
            path="/support/student-hub-library/student-hub/duplicateStudentTabList/:id"
            component={CreateTab}
          />
          <PrivateRoute
            exact
            path="/support/student-hub-library/student-hub/open/:actionId/:audittrail"
            component={AuditTrail}
          />

          <PrivateRoute
            exact
            permission="stuhmsadd"
            path="/support/student-hub-library/student-hub/:tabId/section/create/new"
            component={CategoriesTabList}
          />
          <PrivateRoute
            exact
            permission="stuhmsedit"
            path="/support/student-hub-library/student-hub/:tabId/section/open/:id"
            component={CategoriesTabList}
          />
          <PrivateRoute
            exact
            path="/support/student-hub-library/student-hub/:tabId/section/duplicateCategory/:id"
            component={CategoriesTabList}
          />
          <PrivateRoute
            exact
            path="/support/student-hub-library/student-hub/:tabId/section/:catId/resource/create"
            component={ResourcesTabList}
          />
          <PrivateRoute
            exact
            path="/support/student-hub-library/student-hub/:tabId/section/:catId/resource/open/:id"
            component={ResourcesTabList}
          />
          <PrivateRoute
            exact
            // permission="shview"
            path="/support/student-hub-library/:tab/:type/:id?/:editType?/:resourceType?"
            component={ResourceManagement}
          />
          {/* <PrivateRoute exact path="/courses" component={Courses} /> 
          <PrivateRoute exact path="/coursesdetails/:id" component={DetailCourses} /> */}
          <PrivateRoute
            exact
            path="/courseAdministration/coursesdetails/:id/:type"
            component={AddNewCourse}
          />
          <PrivateRoute
            exact
            path="/courseAdministration/courses/addcourse"
            component={AddNewCourse}
          />

          {/* <PrivateRoute
            exact
            // permission="cview"
            path="/courseAdministration/templateTable/show"
            component={TemplateTable}
          /> */}
          <PrivateRoute
            exact
            // permission="cview"
            path="/courseAdministration/gradingTemplate/:tab/:id?"
            component={TemplateTable}
          />
          {/* <PrivateRoute
            exact
            // permission="cview"
            path="/courseAdministration/coursesdetail/:id/students/:tab/:stdId"
            component={TemplateTable}
          /> */}
          <PrivateRoute
            exact
            // permission="cview"
            path="/courseAdministration/coursesdetail/:id/students/:tab/:stdId"
            component={StudentCourseGradeBook}
          />
          <PrivateRoute
            exact
            permission="cview"
            path="/courseAdministration/courses/:tab"
            component={Courses}
          />
          <PrivateRoute
            exact
            permission="cbview"
            path="/courseAdministration/bookedcourses"
            component={BookedCourses}
          />
          <PrivateRoute
            exact
            permission="cbview"
            path="/courseAdministration/bookedcourses/open/:id"
            component={OpenCourseBookings}
          />
          {/* <PrivateRoute
            exact
            // permission="cview"
            path="/courseAdministration/courses/addTemplate/:id"
            component={TemplateTable}
          /> */}
          <PrivateRoute
            exact
            // permission="cview"
            path="/courseAdministration/certificates/:tab/:id?"
            component={CertificateTemplate}
          />
          <PrivateRoute
            exact
            // permission="cview"
            path="/courseAdministration/coursesdetails/duplicateTemplate"
            component={DuplicateTemplate}
          />

          <PrivateRoute
            exact
            path="/courseAdministration/coursesdetails/:id/:tab/:type/:subTab?/:openTabs?/:subId?/:subType?/:subSubId?"
            component={DetailCourses}
          />

          <PrivateRoute
            exact
            path="/courseAdministration/registration/:page/:tab?/:id?"
            component={ReRegistration}
          />
          <PrivateRoute
            exact
            permission="cquestionbadd"
            path="/courseAdministration/smartAssessment/questionBank/question/duplicate/:id"
            component={DuplicateQuestion}
          />
          <PrivateRoute
            exact
            permission="cquestionbadd"
            path="/courseAdministration/smartAssessment/addquestion/:id?"
            component={AddQuestion}
          />
          <PrivateRoute
            exact
            permission="cquizmadd"
            path="/courseAdministration/assessment/quiz/addquiz"
            component={AddAssessment}
          />
          <PrivateRoute
            exact
            permission="cquizmadd"
            path="/courseAdministration/quizzes/duplicateassessment/:id"
            component={AddAssessment}
          />
          <PrivateRoute
            exact
            path="/courseAdministration/smartAssessment/:tabName?/:tab?/:subtab?/:id?/:subId?"
            component={Courses}
          />
          <PrivateRoute
            exact
            path="/courseAdministration/assessment/:tabName?/:tab?/:subtab?/:id?/:subId?"
            component={Courses}
          />
          {/* <PrivateRoute exact permission="cview" path="/courseAdministration/programs/:tab/:type?/:id?/:subtype?" component={Programs} /> */}
          <PrivateRoute exact path="/gdc" component={HomePage} />
          <PrivateRoute
            exact
            path="/courseAdministration/Programmes/:tab/:type?/:id?/:subtype?/:subId?/:subTab?/:openTabs?"
            component={Programs}
          />
          {/* <PrivateRoute
            exact
            path="/websiteManagement/pages/programmes_and_short_courses/:tab/:type/:id?"
            component={ProgrammeShortCourse}
          /> */}
          <PrivateRoute
            exact
            path="/websiteManagement/pages/programmes_and_short_courses/:tab/:subTab/:type?/:id?"
            component={ProgrammeShortCourse}
          />
          <PrivateRoute
            exact
            path="/courseAdministration/gradeMix/:id?/:tab/:subId?/:type"
            component={GradeMix}
          />

          <PrivateRoute exact path="/gdc" component={HomePage} />
          <PrivateRoute exact path="/status" component={StudentStatus} />
          <PrivateRoute
            exact
            permission="pmview"
            path="/permissionManagement"
            component={PermissionManagement}
          />
          <PrivateRoute
            exact
            path="/systemAdministration/permissionManagement/:tab/:type?/:roleName?/:id?"
            component={PermissionManagement}
          />

          <PrivateRoute
            exact
            path="/administration/netsuite/:tab/:type?/:id?"
            component={NetsuiteManagement}
          />
          <PrivateRoute
            exact
            path="/notification/email/:tab/:type/:id?"
            component={EmailManagement}
          />
          <PrivateRoute
            exact
            path="/notification/bulknotification/:tab/:type/:id?"
            component={Notification}
          />
          {/* <PrivateRoute exact path="/content" component={ContentAssemblies} /> */}
          {/* <PrivateRoute exact path="/contentassembliesdetails/create" component={AssembliesDetails} /> */}
          {/* <PrivateRoute exact path="/contentassembliesdetails/edit" component={AssembliesDetails} /> */}
          {/* <PrivateRoute exact path="/contentassembliesdetails/duplicate" component={AssembliesDetails} /> */}
          <PrivateRoute exact path="/content" component={ContentAssemblies} />
          <PrivateRoute
            exact
            path="/contentassembliesdetails/create"
            component={AssembliesDetails}
          />
          <PrivateRoute
            exact
            path="/contentassembliesdetails/edit"
            component={AssembliesDetails}
          />
          {/* <PrivateRoute exact path="/contentassembliesdetails/edit/:assemblyId" component={AssembliesDetails} /> */}
          <PrivateRoute
            exact
            path="/contentassembliesdetails/duplicate"
            component={AssembliesDetails}
          />

          <PrivateRoute
            exact
            path="/content/resourcedetails"
            component={ResourcePoolDetails}
          />

          <PrivateRoute
            exact
            path="/program-management-center"
            component={ProgramManagementCenter}
          />
          <PrivateRoute
            exact
            path="/program-management-center/:sideTab/:tab/:type/:id?/:subId?"
            component={ProgramManagementCenter}
          />
          <PrivateRoute
            exact
            path="/program-management-center/exportProgramme/:id"
            component={ExportPDF}
          />

          {/* routes by yash start */}

          {/* <PrivateRoute exact path="/content/:tab" component={ContentAssemblies} /> */}
          <PrivateRoute
            exact
            path="/courses/content/:tab/:type/:id?/:subType?/:subId?"
            component={ContentAssemblies}
            permission="caview"
          />
          {/* <PrivateRoute exact path="/content/audit-trail" component={ContentAssemblies} /> */}
          {/* <PrivateRoute exact path="/content/:tab/:type?/:resourcePoolId?" component={ResourcePoolDetails} /> */}
          {/* <PrivateRoute exact path="/content/:tab/:type/:resourcePoolId/:subTab/:subTabType?/:subTabId?" component={SubCategoryDetails} /> */}
          <PrivateRoute
            exact
            path="/content/:tab/:type/:resourcePoolId/:subTab/:subTabType?/:subTabId?"
            component={ResourcePoolSubCatDetails}
          />

          {/* routes by yash end */}

          <PrivateRoute
            exact
            permission="sslciew"
            path="/support/live-chat"
            component={LiveChat}
          />
          {/* <PrivateRoute exact path="/support" component={SupportAdministration} /> */}
          {/* <PrivateRoute exact path="/support/live-chat" component={LiveChat} /> */}
          <PrivateRoute
            exact
            path="/support/:tab?"
            component={SupportAdministration}
          />
          <PrivateRoute
            exact
            permission="tview"
            path="/support"
            component={SupportAdministration}
          />
          <PrivateRoute exact path="/addticket" component={AddTicket} />
          <PrivateRoute
            exact
            path="/addticketStudent/:id/:stdName"
            component={AddTicket}
          />
          <PrivateRoute exact path="/editticket/:id" component={ViewTicket} />
          <PrivateRoute
            exact
            path="/editticket/:id/audittrial"
            component={(props) => <ViewTicket {...props} purpose="ticketaudit" />}
          />

          <PrivateRoute
            exact
            path="/studentAdministration/students/:type/:id?/:tab?/:subType?/:subId?"
            component={StudentAdministration}
          />
          <PrivateRoute
            exact
            path="/campusAdministration/bookings/:type/:id?"
            component={BookingManagement}
          />
          <PrivateRoute
            exact
            path="/campusAdministration/schedule/calender"
            component={ScheduleManagement}
          />
          <PrivateRoute
            exact
            path="/studentAdministration/externalExam/:tab/:type?/:subTab?/:id?/:subType?/:subId?"
            component={ExternalExamReg}
          />
          <PrivateRoute
            exact
            path="/studentadministration/registration/:page/:tab?/:id?"
            component={ReRegistration}
          />
          <PrivateRoute
            exact
            path="/courseAdministration/articulation/:page/:tab?/:id?"
            component={ProgramArticulations}
          />
          <PrivateRoute
            exact
            path="/courseAdministration/intake-qualification/:page/:tab?/:id?"
            component={IntakeCopy}
          />
          <PrivateRoute
            exact
            permission="pmview"
            path="/permissionManagement"
            component={PermissionManagement}
          />
          {/* <PrivateRoute exact  path="/administration/netsuite/customers/:type?/:id?" component={NetsuiteManagement} /> */}
          <PrivateRoute
            exact
            path="/administration/netsuite/mergedData/table/:subTab"
            component={NetsuiteManagement}
          />
          <PrivateRoute
            exact
            path="/administration/netsuite/:tab/:type?/:id?"
            component={NetsuiteManagement}
          />
          <PrivateRoute
            exact
            path="/administration/general/:tab"
            component={General}
          />
          <PrivateRoute
            exact
            path="/administration/serverManagement/:tab"
            component={ServerManagement}
          />
          <PrivateRoute
            exact
            path="/administration/classManagement/:tab"
            component={ClassManagement}
          />
          <PrivateRoute
            exact
            path="/administration/campusManagement/:tab"
            component={CampusManagement}
          />
          <PrivateRoute
            exact
            path="/administration/softphoneManagement/:tab"
            component={SoftphoneManagement}
          />
          <PrivateRoute
            exact
            permission="tsview"
            path="/administration/ticketSettings/:tab/:type?/:id?"
            component={TicketSetting}
          />
          <PrivateRoute
            exact
            path="/websiteManagement/siteSetup/:type"
            component={Website}
          />
          <PrivateRoute
            exact
            path="/websiteManagement/siteSetup/brand_Templates/:type/:subType?/:subId?"
            component={CreateBrandTemplate}
          />
          <PrivateRoute
            exact
            path="/websiteManagement/siteSetup/table/:type/:id?"
            component={AddWebsiteList}
          />
          <PrivateRoute
            exact
            path="/websiteManagement/events/:tab/:type"
            component={Events}
          />
          <PrivateRoute
            exact
            path="/websiteManagement/events/landing_page/:tab/:subTab?/:subId?"
            component={LandingPageTabs}
          />
          <PrivateRoute
            exact
            path="/websiteManagement/events/upcoming_events/:tab/:subTab?/:subId?"
            component={UpcomingEventsTabs}
          />
          <PrivateRoute
            exact
            path="/websiteManagement/events/info_packs/:tab/:subTab?/:subId?"
            component={InfoPacksTabs}
          />
          <PrivateRoute
            exact
            path="/websiteManagement/layout_navigation/:tab/:type"
            component={LayoutNavigation}
          />
          {/* <PrivateRoute
            exact
            path="/websiteManagement/layout_navigation/:tab/:subTab/:type/:id"
            component={LayoutMenusCreate}
          /> */}
          {/* <PrivateRoute
            exact
            path="/websiteManagement/layoutNavigation/header/:tab/:subTab?/:subId?"
            component={HeaderTabs}
          /> */}
          <PrivateRoute
            exact
            path="/websiteManagement/layoutNavigation/testimonials/:tab/:subTab?/:subId?"
            component={CreateLayoutTestimonial}
          />
          <PrivateRoute
            exact
            path="/websiteManagement/layout_navigation/:tab/:subTab?/:type/:subId?"
            component={CategoryTabs}
          />

          {/* pages route */}

          <PrivateRoute
            exact
            path="/website_management/pages/staticpages/:tab"
            component={StaticPages}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/staticpages/table/:tab/:id?"
            component={CreateStaticPages}
          />
          <PrivateRoute
            path="/website_management/pages/campuspages/table/:type/:id?"
            component={CreateCampusPages}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/campuspages/:tab"
            component={CampusPages}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/popupspages/table/:type/:id?"
            component={CreatePopupsPages}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/popupspages/:tab"
            component={PopupsPages}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/emailspages/table/:type/:id?"
            component={CreateEmailsPages}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/emailspages/:tab"
            component={EmailsPages}
          />
          <PrivateRoute
            exact
            path="/website_management/partners/table"
            component={PartnerManagement}
          />
          <PrivateRoute
            exact
            path="/website_management/partnerDetails/:id"
            component={PartnerDetails}
          />
          <PrivateRoute
            exact
            permission="partnerview"
            path="/website_management/partners/auditTrail"
            component={AuditTrailPartner}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/categorylandingpages/table/:type/:id?"
            component={CreateCategoryLandingPages}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/categorylandingpages/:tab"
            component={CategoryLandingpages}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/courselandingpages/:tab"
            component={CourseLandingPages}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/courselandingpages/table/:type/:id?"
            component={CreateCourseLandingPages}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/blogandnews/:tab"
            component={BlogAndNews}
          />
          <PrivateRoute
            exact
            path="/website_management/pages/blogandnews/table/:type/:id?"
            component={CreateBlogAndNewsPages}
          />
          <PrivateRoute
            exact
            path="/supportAdministration/:sideTab/:tab/:type/:id?"
            component={Appeals}
          />
          <PrivateRoute
            exact
            path="/studentAdministration/students/:type/:id?/:tab?/:subType?"
            component={StudentAdministration}
          />
          {/* <PrivateRoute exact permission="shview" path="/support/student-hub-library/student-hub/:page?" component={LiveChat} /> */}

          <PrivateRoute
            exact
            path="/systemAdministration/:sideTab/:tab/:type?/:roleName?/:id?"
            component={PermissionManagement}
          />

          <PrivateRoute
            exact
            path="/studentadministration/:page/:tab?"
            component={ReRegistration}
          />
          {/* <PrivateRoute exact  path="/administration/netsuite/:tab/:type?/:id?" component={NetsuiteManagement} /> */}
          <PrivateRoute
            exact
            path="/administration/netsuite/customers/:type?/:id?"
            component={NetsuiteManagement}
          />

          <PrivateRoute
            exact
            path="/systemAdministration/permissionManagement/:tab/:type?/:roleName?/:id?"
            component={PermissionManagement}
          />
          <PrivateRoute
            exact
            path="/classReport/:tab/:intake/:cid"
            component={ClassReport}
          />
          <PrivateRoute exact path="/fileSystem" component={FileSystem} />
          {/* deon routes start */}
          <PrivateRoute
            exact
            path="/DashBoardDesign1"
            component={DashBoardDesign1}
          />
          <PrivateRoute
            exact
            permission="cview"
            path="/courseAdministration/coursesDeon/:tab"
            component={CoursesDeonDesign}
          />
          <PrivateRoute
            exact
            permission="cview"
            path="/courseAdministration/coursesDeonUnknown/:tab"
            component={CoursesDeonDesignUnknown}
          />
          {/* <PrivateRoute
            exact
            permission="cview"
            path="/courseAdministration/coursesDeonknown/:tab"
            component={CoursesDeonDesignknown}
          /> */}
          {/* deon reroutes end */}
        </Switch>
      </Router>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
